@import url('https://fonts.googleapis.com/css?family=Pacifico&display=swap');

a {
  color: rgba(0, 0, 0, 0.5);
}

a:hover {
  color: black;
}

.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.Index-container {
  background-color: rgba(0, 0, 0, 0.2) !important;
  border-style: none !important;
}

.Container {
  background: #fafafa;
}

.Index-container-card {
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  /* background: #fafafa; */

  /* border-style: none !important; */
  /* box-shadow: none !important; */
}

.Index-wrapper {
  height: 100vh !important;
  width: 100vw !important;
  background-color: transparent;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.Share-link > input {
  cursor: pointer !important;
}

.Hero-title {
  font-family: 'Pacifico', cursive !important;
}

.Hero-title-subtitle {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
    Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-size: 1.5rem;
  color: black !important;
  font-weight: 300 !important;
}

.Header-image {
  width: 200px;
}

@media only screen and (max-width: 600px) {
  .Hero-title {
    font-size: 2rem !important;
    margin-bottom: 2rem !important;
  }

  .Header-image {
    width: 150px;
  }
}

input:focus {
  border-color: orange !important;
}
